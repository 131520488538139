import Alpine from "alpinejs";
import marquee from "vanilla-marquee";
Alpine.start();
const examples = [
    {
        "headline": "Revolutionize Your Fitness Routine",
        "description": "Experience cutting-edge personal training with our AI-powered app. Join now and unlock your true potential!"
    },
    {
        "headline": "Taste The Future of Gourmet Cuisine",
        "description": "Dive into a gastronomic adventure with our innovative molecular gastronomy courses. Elevate your cooking skills today!"
    },
    {
        "headline": "Reliable & Eco-Friendly Solar Solutions",
        "description": "Power your home with clean, renewable energy. Get a free solar consultation and start saving on your energy bills now!"
    },
    {
        "headline": "Expert Digital Marketing Strategies",
        "description": "Unlock your business's online potential with our tailored marketing campaigns. Increase your revenue and grow your brand today!"
    },
    {
        "headline": "Luxurious Vacation Rentals Await",
        "description": "Discover your dream getaway with our curated collection of stunning vacation homes. Book now for an unforgettable escape!"
    },
    {
        "headline": "Transform Your Smile with Invisalign",
        "description": "Achieve a perfect smile discreetly and comfortably with our Invisalign treatment. Schedule your free consultation today!"
    },
    {
        "headline": "Create Your Dream Home with Us",
        "description": "Our award-winning architects and builders craft custom homes that reflect your unique vision. Start designing your dream home now!"
    },
    {
        "headline": "Effortless Online Accounting Services",
        "description": "Streamline your business finances with our cloud-based accounting solutions. Focus on growth while we manage the numbers!"
    },
    {
        "headline": "Advanced Cybersecurity Protection",
        "description": "Safeguard your digital assets with our comprehensive security solutions. Protect your business from cyber threats today!"
    },
    {
        "headline": "Unleash Your Creative Genius",
        "description": "Discover our range of art and design workshops led by industry professionals. Ignite your passion and master your craft now!"
    }
];
window.addEventListener('load', () => {
    const els = document.getElementsByClassName('login');
    const nonce = Date.now();
    for (let i = 0; i < els.length; i++) {
        els[i].href = 'https://accounts.google.com/o/oauth2/v2/auth?response_type=id_token&client_id=674515207668-t5bvcd6l6218kbouoq2hfejc90oloq7g.apps.googleusercontent.com&redirect_uri=https%3A%2F%2Fapp.adwise.ai%2Fredirect.html%3Ffrom%3D' + i + '&scope=openid%20profile%20email&nonce=' + nonce;
    }
    document.getElementById( 'marquee' ).innerHTML = `<ul role="list" class="w-full mt-3 flex space-x-2">${examples.map(({headline, description}) => {
        return `<li class="flex w-96 rounded-md shadow-sm">
            <div class="flex-1 truncate rounded-md border border-gray-200 bg-whit px-4 py-2 text-sm">
                <span class="font-semibold text-gray-900">${headline}</span>
                <p class="text-gray-500">${description}</p>
            </div>
        </li>`; 
    }).join('')}</ul>`;
    new marquee( document.getElementById( 'marquee' ), {
        speed: 60,
        startVisible: true,
        delayBeforeStart: 0,
        duplicated: true,
    });
});